<template lang="pug">
    .main-wrapper.unidades-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Cirurgias</b>

        Dialog.dialogApagar(header='Remover Cirurgia' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover a cirurgia <b>{{ dialogApagar_data.nm_unidade }}</b>?
            .ta-center.my-4
                ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='remove()')

        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-4
                    label.form-label Descrição:
                    .p-inputgroup
                        InputText(
                            placeholder='Descrição'
                            @keyup.enter.native='applyFilters()'
                            @input='(value) => { if (!value) applyFilters() }'
                            v-model='filters.ds_descricao'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')
                .p-col-12.p-md-3
                    label.form-label Procedimento:
                    .p-inputgroup
                        InputText(
                            placeholder='Procedimento'
                            @keyup.enter.native='applyFilters()'
                            @input='(value) => { if (!value) applyFilters() }'
                            v-model='filters.ds_procedimento'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')
                .p-col-12.p-md-3
                    label.form-label Estabelecimento:
                    ProgressBar(v-if='waitingEstabelecimento' mode="indeterminate")
                    Dropdown(
                        v-else
                        v-model='filters.cd_estabelecimento'
                        :options='filters_options.estabelecimentos'
                        optionLabel='text'
                        :filter='true'
                        optionValue='value'
                        dataKey='value'
                        placeholder='TODOS'
                        @change='applyFilters()'
                    )
                .p-col-12.p-md-1
                    label.form-label Tipo:
                    Dropdown(
                        v-model='filters.ie_pacote'
                        :options='filters_options.tipo'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        placeholder='TODOS'
                        @change='applyFilters()'
                    )
                .p-col-12.p-md-1
                    label.form-label Status:
                        Dropdown(
                            v-model='filters.ie_status'
                            :options='filters_options.ieStatus'
                            optionLabel='text'
                            optionValue='value'
                            dataKey='value'
                            placeholder='TODOS'
                            @change='applyFilters()'
                        )

        .ta-right.my-2
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/cirurgias/salvar/0/')")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.ds_descricao" style='position: relative')
                            .ta-left
                                p <b>Descrição:</b> {{ props.data.ds_descricao }}

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de Cirurgias')
                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                DataTable(:value="list")

                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center;')
                        template(#body='props')
                            ProgressSpinner(v-if='waitingStatus.ie_status_id.includes(props.data.id)' strokeWidth='6')
                            .status-indicator(
                                    v-else :class="{ off: ! props.data.ie_status }"
                                    @click='alterarStatus(props.data, "ie_status")')
                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle='width: 5%;' field='id' header='Cod.' bodyStyle='text-align: center;')
                    Column(headerStyle='width: 20%;' bodyStyle='overflow-wrap: break-word;' field='ds_descricao' header='Descrição')
                    Column(headerStyle='width: 20%;' field='cd_estabelecimento.nm_fantasia' header='Estabelecimento')
                    Column(headerStyle='width: 20%;' bodyStyle='overflow-wrap: break-word' field='cd_especialidades_f' header='Especialidades')
                    Column(headerStyle='width: 10%;' field='tipo_f' header='Tipo' bodyStyle='text-align:center')
                    Column(headerStyle='width: 12%;' header='Valor total' bodyStyle='text-align:right')
                        template(#body='props')
                            span(v-tooltip.top="props.data.nr_valor_total") {{ formatPrice(props.data.nr_valor_total) }}
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' header='App cliente')
                        template(#body='props')
                            ProgressSpinner(v-if='waitingStatus.ie_app_cliente_id.includes(props.data.id)' strokeWidth='6')
                            .status-indicator(
                                    v-else :class="{ off: ! props.data.ie_app_cliente }"
                                    @click='alterarStatus(props.data, "ie_app_cliente")')
                                i.jam(:class="{ 'jam-eye': props.data.ie_app_cliente, 'jam-eye-close': !props.data.ie_app_cliente }")
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' header='App médico')
                        template(#body='props')
                            ProgressSpinner(v-if='waitingStatus.ie_app_medico_id.includes(props.data.id)' strokeWidth='6')
                            .status-indicator(
                                    v-else :class="{ off: ! props.data.ie_app_medico }"
                                    @click='alterarStatus(props.data, "ie_app_medico")')
                                i.jam(:class="{ 'jam-eye': props.data.ie_app_medico, 'jam-eye-close': !props.data.ie_app_medico }")
                    Column(headerStyle='width: 15%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Criar orçamento'"
                                    icon="jam jam-user-plus"
                                    @click="$router.push(`/cirurgias/salvar/${ props.data.id }/?orcamento=true`)"
                                    )
                                div(v-if="usuario.nivel >= 5 || usuario.username === props.data.nm_usuario_cri" style="display: contents")
                                    Button.p-button-raised.p-button-rounded.mr-1(
                                        v-tooltip.top="'Editar'"
                                        icon="jam jam-write"
                                        @click="$router.push(`/cirurgias/salvar/${ props.data.id }/`)"
                                        )
                                    Button.p-button-raised.p-button-rounded.p-button-danger(
                                        v-tooltip.top="'Apagar'"
                                        icon="jam jam-minus-circle"
                                        @click="dialogApagar = true; dialogApagar_data = props.data"
                                        )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .unidades-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
            .p-progress-spinner {
                max-width: 23px;
                height: auto;
            }
        }
        .dialogApagar {
            .p-progress-spinner {
                width: 29px;
                height: auto;
            }
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
    }
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import DataView from 'primevue/dataview'
    import Dropdown from 'primevue/dropdown'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Button from 'primevue/button'
    import Tooltip from 'primevue/tooltip'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import ProgressSpinner from 'primevue/progressspinner'

    import {Cirurgia, Estabelecimento} from './../../middleware'
	import wsConfigs from './../../middleware/configs'
    export default {
        beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if (from.path.split('/')[1] === routeName && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
                vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
        created () {
            this.getEstabelecimentos()
            //this.applyFilters()
            this.usuario = JSON.parse(localStorage.getItem('usuario'))
        },
        components: { ProgressBar, DataView, Dropdown, Panel, Paginator, DataTable,
            Column, Button, Tooltip, Dialog, ProgressSpinner, InputText },
        directives: { tooltip: Tooltip },
        data () {
            return {
                usuario: null,
                list: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingStatus: {
                    ie_status_id: [],
                    ie_app_cliente_id: [],
                    ie_app_medico_id: [],
                },
                waitingEstabelecimento: false,
                waitingApagar: false,
                dialogApagar: false,
                dialogApagar_data: {},
                filters: {
                    ds_descricao: '',
                    ds_procedimento: '',
                    ie_pacote: null,
                    cd_estabelecimento: null,
                    ie_status: 'true'
				},
                filters_options: {
                    tipo: [
                        { value: null, text: 'TODOS' },
                        { value: true, text: 'Pacote' },
                        { value: false, text: 'Conta aberta' }
                    ],
                    ieStatus: [
                        { value: null, text: 'TODOS' },
                        { value: 'true', text: 'Ativos' },
                        { value: 'false', text: 'Inativos' }
                    ],
                    estabelecimentos: []
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        
        methods: {
            formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
            getEstabelecimentos () {
                this.waitingEstabelecimento = true
                Estabelecimento.findAll().then(response => {
                    this.waitingEstabelecimento = false
                    if (response.status === 200) {
                        this.filters_options.estabelecimentos = response.data.map(e => {
                            return { text: e.nm_fantasia, value: e.id }
                        })
                        this.filters_options.estabelecimentos.unshift({ text: 'TODOS', value: null })
                    }
                })
            },
            getList (params) {
                this.waiting = true
                return Cirurgia.findAll(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        response.data.results.forEach(cirurgia => {
                            cirurgia.tipo_f = cirurgia.ie_pacote ? 'Pacote' : 'Conta aberta'
                            if (cirurgia.cd_especialidades) {
                                cirurgia.cd_especialidades_f = cirurgia.cd_especialidades.map(esp => {
                                    return esp.nm_especialidade
                                }).join(", ")
                            }
                        })
                        this.list = response.data.results
                    }
                    this.waiting = false
                    return true
                })
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => {
                    if (key === 'ie_pacote' && this.filters[key] !== null) params[key] = this.filters[key]
                    else if (this.filters[key]) params[key] = this.filters[key]
                })
                this.getList(params)
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            remove () {
                this.waitingApagar = true
                Cirurgia.remove(this.dialogApagar_data.id).then(response => {
                    this.waitingApagar = false
                    if (([200, 201, 204]).includes(response.status)) {
                        this.$toast.info('Cirurgia removida com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            alterarStatus (cirurgia, key) {
                let dataSend = {id: cirurgia.id}
                dataSend[key] = !cirurgia[key]

                this.waitingStatus[key+"_id"] = [...this.waitingStatus[key+"_id"], cirurgia.id]
                Cirurgia.alterarStatus(cirurgia.id, dataSend).then(response => {
                    this.waitingStatus[key+"_id"] = this.waitingStatus[key+"_id"].filter(id => id !== cirurgia.id)
                    if ([200, 201, 204].includes(response.status)) {
                        cirurgia[key] = response.data[key]
                        this.$toast.success('Alterado com sucesso.', {duration: 3000})
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
        }
    }
</script>
